import { useState } from 'react';
import { CircleFilterValues, TabFilterValues } from './useResidentsPage';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useResidentTableFilters = () => {
  const [filterTerms, setFilterTerms] = useState<string>('');
  const [circleFilterValue, setCircleFilterValue] =
    useState<CircleFilterValues>(CircleFilterValues.ACCOUNT_BALANCE);
  const [tabFilterValue, setTabFilterValueBase] = useState<TabFilterValues>(
    TabFilterValues.ALL
  );

  return {
    filterTerms,
    setFilterTerms,
    circleFilterValue,
    setCircleFilterValue,
    tabFilterValue,
    setTabFilterValueBase,
  };
};
