import type { NavBarExtension } from '@pflegenavi/frontend/layout';
import { useFeatureFlag } from '@pflegenavi/frontend/feature-flags';
import { FeatureFlag, FeatureFlagStatus } from '@pflegenavi/shared/api';
import { nhAppMainPages } from '@pflegenavi/frontend/routing';

import recurringItemsIcon from '../assets/recurring_items_icon.svg';
import { SvgIconStyle } from '@pflegenavi/web-components';
import { useTranslation } from 'react-i18next';

export const useRecurringItemsNavConfig: () => NavBarExtension = () => {
  const enabled = useFeatureFlag(FeatureFlag.RecurringItems);

  const { t } = useTranslation();

  if (enabled === FeatureFlagStatus.Disabled) {
    return undefined;
  }

  return {
    item: {
      icon: (
        <SvgIconStyle src={recurringItemsIcon} sx={{ width: 20, height: 20 }} />
      ),
      title: t('residents.recurring-items'),
      path: nhAppMainPages.RECURRING_ITEMS_V2,
      children: [
        {
          title: t('common.overview'),
          path: nhAppMainPages.RECURRING_ITEMS_V2,
        },
      ],
    },
  };
};
