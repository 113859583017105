import { Route } from 'react-router-dom';
import { nhAppMainPages } from '@pflegenavi/frontend/routing';
import { useFeatureFlag } from '@pflegenavi/frontend/feature-flags';
import { FeatureFlag, FeatureFlagStatus } from '@pflegenavi/shared/api';
import { lazy, useMemo } from 'react';
import { DefaultSuspense } from '@pflegenavi/web-components';
import AccountingServiceProviderPaymentsPage from './AccountingServiceProviderPaymentsPage';
import { OverviewPageNew } from './OverviewPageNew';
import {
  CashOverviewPageV3,
  CashTransactionGroupDetailPage,
  CashTransactionsPage,
} from '@pflegenavi/frontend/pages/cash-management';

const OverviewPage = lazy(() => import('./OverviewPage'));
const AccountingPayoutsPage = lazy(() => import('./AccountingPayoutsPage'));
const AccountingPaymentsPage = lazy(() => import('./AccountingPaymentsPage'));

export const useNursingHomeAccountingRoutes = (): JSX.Element | null => {
  const accountingPaymentsFeatureFlagEnabled =
    useFeatureFlag(FeatureFlag.AccountingPayments) ===
    FeatureFlagStatus.Enabled;
  const newAccountingPaymentsFeatureFlagEnabled =
    useFeatureFlag(FeatureFlag.AccountingPaymentsNew) ===
    FeatureFlagStatus.Enabled;

  const serviceProviderFeatureFlagEnabled =
    useFeatureFlag(FeatureFlag.ServiceProviderPayments) ===
    FeatureFlagStatus.Enabled;

  const coinListFeatureFlagEnabled =
    useFeatureFlag(FeatureFlag.CoinList) === FeatureFlagStatus.Enabled;

  // eslint-disable-next-line complexity
  return useMemo(() => {
    if (
      !accountingPaymentsFeatureFlagEnabled &&
      !newAccountingPaymentsFeatureFlagEnabled
    ) {
      return null;
    }

    if (newAccountingPaymentsFeatureFlagEnabled) {
      return (
        <Route path={nhAppMainPages.NURSING_HOME_ACCOUNTING}>
          <Route
            index={true}
            element={
              <DefaultSuspense>
                <OverviewPageNew />
              </DefaultSuspense>
            }
          />
          {coinListFeatureFlagEnabled && (
            <>
              <Route
                path={nhAppMainPages.NURSING_HOME_ACCOUNTING_OVERVIEW_NEW}
                element={
                  <DefaultSuspense>
                    <OverviewPageNew />
                  </DefaultSuspense>
                }
              />

              <Route
                path={nhAppMainPages.NURSING_HOME_ACCOUNTING_TRANSACTION_GROUPS}
                element={
                  <DefaultSuspense>
                    <CashOverviewPageV3 />
                  </DefaultSuspense>
                }
              />

              <Route
                path={nhAppMainPages.NURSING_HOME_ACCOUNTING_TRANSACTIONS}
                element={
                  <DefaultSuspense>
                    <CashTransactionsPage />
                  </DefaultSuspense>
                }
              />
            </>
          )}

          <Route
            path={nhAppMainPages.NURSING_HOME_ACCOUNTING_GROUP_DETAIL}
            element={<CashTransactionGroupDetailPage />}
          />

          <Route
            path={nhAppMainPages.NURSING_HOME_ACCOUNTING_PAYOUTS}
            element={
              <DefaultSuspense>
                <AccountingPayoutsPage />
              </DefaultSuspense>
            }
          />
          <Route
            path={nhAppMainPages.NURSING_HOME_ACCOUNTING_PAYMENTS}
            element={<AccountingPaymentsPage />}
          />
          {serviceProviderFeatureFlagEnabled ? (
            <Route
              path={
                nhAppMainPages.NURSING_HOME_ACCOUNTING_SERVICE_PROVIDER_PAYMENTS
              }
              element={
                <DefaultSuspense>
                  <AccountingServiceProviderPaymentsPage />
                </DefaultSuspense>
              }
            />
          ) : null}
        </Route>
      );
    }

    return (
      <Route path={nhAppMainPages.NURSING_HOME_ACCOUNTING}>
        <Route
          index={true}
          element={
            <DefaultSuspense>
              <OverviewPage />
            </DefaultSuspense>
          }
        />
        <Route
          path={nhAppMainPages.NURSING_HOME_ACCOUNTING_OVERVIEW}
          element={
            <DefaultSuspense>
              <OverviewPage />
            </DefaultSuspense>
          }
        />

        <Route
          path={nhAppMainPages.NURSING_HOME_ACCOUNTING_OVERVIEW_NEW}
          element={
            <DefaultSuspense>
              <OverviewPageNew />
            </DefaultSuspense>
          }
        />
        <Route
          path={nhAppMainPages.NURSING_HOME_ACCOUNTING_PAYOUTS}
          element={
            <DefaultSuspense>
              <AccountingPayoutsPage />
            </DefaultSuspense>
          }
        />
        <Route
          path={nhAppMainPages.NURSING_HOME_ACCOUNTING_PAYMENTS}
          element={<AccountingPaymentsPage />}
        />
        {serviceProviderFeatureFlagEnabled ? (
          <Route
            path={
              nhAppMainPages.NURSING_HOME_ACCOUNTING_SERVICE_PROVIDER_PAYMENTS
            }
            element={
              <DefaultSuspense>
                <AccountingServiceProviderPaymentsPage />
              </DefaultSuspense>
            }
          />
        ) : null}
      </Route>
    );
  }, [
    accountingPaymentsFeatureFlagEnabled,
    coinListFeatureFlagEnabled,
    newAccountingPaymentsFeatureFlagEnabled,
    serviceProviderFeatureFlagEnabled,
  ]);
};
