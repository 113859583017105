import type { FC } from 'react';
import React, { useCallback, useMemo, useState } from 'react';
import { useNursingHomeContext } from '@pflegenavi/frontend/nursing-home-context';
import type { CashList } from '@pflegenavi/frontend/api-nursing-home';
import { useMoneyFlow } from '@pflegenavi/frontend/api-nursing-home';
import {
  useCashListConfiguration,
  useImage,
  usePaymentDashboardStatistics,
  usePendingReversals,
  useServiceProviderPayments,
} from '@pflegenavi/frontend/api-nursing-home';
import {
  Box,
  Card,
  Container,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { useFormatting } from '@pflegenavi/frontend/localization';
import {
  Button,
  DropdownSplitButton,
  Iconify,
  LoadingContainer,
  Modal,
  ModalInner,
  useErrorSnackbar,
} from '@pflegenavi/web-components';
import type { PaymentDashboardStatisticsDto } from '@pflegenavi/shared/api';
import {
  CashListStorageType,
  FeatureFlag,
  FeatureFlagStatus,
  PaymentStatus,
} from '@pflegenavi/shared/api';
import { useTranslation } from 'react-i18next';
import { NursingHomeAccountingBreadcrumbs } from './NursingHomeAccountingBreadcrumbs';
import { nhAppMainPages } from '@pflegenavi/frontend/routing';
import {
  pflegenaviBase64Logo,
  usePrintPdfAccountingOverview,
} from '@pflegenavi/frontend/pdf-export';
import { makeSafeFilename } from '@pflegenavi/shared/utils';
import {
  endOfDay,
  endOfMonth,
  endOfYear,
  format,
  isBefore,
  isSameDay,
  max,
  startOfDay,
  startOfMonth,
  startOfYear,
  subMonths,
  subYears,
} from 'date-fns';
import { GroupContainer } from './overview/GroupContainer';
import { RowDivider } from './overview/RowDivider';
import { Row } from './overview/Row';
import { Title } from './overview/Title';
import { Bank } from './overview/Bank';
import { Cash } from './overview/Cash';
import {
  useFeatureFlag,
  useFeatureFlagEnabled,
} from '@pflegenavi/frontend/feature-flags';
import { NewAccountingOverviewTotals } from './components/NewAccountingOverviewTotals';
import { Stripe } from './overview/Stripe';
import { ChangeCashModal } from '@pflegenavi/frontend/pages/cash-management';
import { DatePicker } from '@mui/x-date-pickers';
import { LoadingButton } from '@mui/lab';
import { csvDownload } from '@pflegenavi/frontend/pages/reporting';
import { formatInTimeZone } from 'date-fns-tz';
import { useGetBatchReceiptDateString } from '@pflegenavi/shared/frontend';

export const OverviewPageNew: FC = () => {
  const { t } = useTranslation();
  const [openChangeCashModal, setOpenChangeCashModal] = useState(false);

  const { selectedNursingHome } = useNursingHomeContext();

  // Data fetches
  const { data, isLoading } = usePaymentDashboardStatistics(
    selectedNursingHome?.id
  );

  const coinListEnabled =
    useFeatureFlag(FeatureFlag.CoinList) === FeatureFlagStatus.Enabled;

  const serviceProviderPaymentsFeatureFlagEnabled =
    useFeatureFlag(FeatureFlag.ServiceProviderPayments) ===
    FeatureFlagStatus.Enabled;

  const reconciliationReportsEnabled = useFeatureFlagEnabled(
    FeatureFlag.dev_ReconciliationReports
  );

  const { data: pendingReversals, isLoading: isPendingReversalsLoading } =
    usePendingReversals(selectedNursingHome?.id);
  // acc - curr.amount is intentional, since total amount should be negative.
  // When using -(reduce(...) ?? 0), the result can be -0, which is not what we want to display.
  const totalPendingReversals =
    pendingReversals?.reduce((acc, curr) => acc - curr.amount, 0) ?? 0;

  const {
    data: serviceProviderPayments,
    isLoading: isLoadingServiceProviderPayments,
  } = useServiceProviderPayments({
    nursingHomeId: selectedNursingHome?.id,
    states: [PaymentStatus.Pending],
    page: 0,
    pageSize: 50,
  });

  const serviceProviderPaymentSum = useMemo(() => {
    return (
      serviceProviderPayments?.data.reduce(
        (acc, curr) => acc + curr.amount,
        0
      ) ?? 0
    );
  }, [serviceProviderPayments]);

  const cashListConfiguration = useCashListConfiguration();

  const cashAccounts = useMemo(() => {
    return cashListConfiguration?.cashLists?.filter(
      (cashList) => cashList.storageType & CashListStorageType.Cash
    );
  }, [cashListConfiguration?.cashLists]);
  const bankAccounts = useMemo(() => {
    return cashListConfiguration?.cashLists?.filter(
      (cashList) => cashList.storageType === CashListStorageType.BankAccount
    );
  }, [cashListConfiguration?.cashLists]);

  const { handlePrintPdfAccountingOverview } = usePrintPdfAccountingOverview();
  const { data: pdfLogo } = useImage(selectedNursingHome?.pdfLogoId);

  const [
    showAccountingOverviewPdfLoading,
    setShowAccountingOverviewPdfLoading,
  ] = useState(false);

  const { enqueueErrorSnackbar } = useErrorSnackbar();

  const showAccountingOverviewPdf = useCallback(async () => {
    try {
      if (!selectedNursingHome || !data) {
        throw new Error('No nursing home selected');
      }

      setShowAccountingOverviewPdfLoading(true);

      const currentDate = new Date();

      const pdfPromise = handlePrintPdfAccountingOverview({
        nursingHomeName: selectedNursingHome.name,
        date: currentDate,
        logo: pdfLogo ? pdfLogo.url : pflegenaviBase64Logo,
        coinListEnabled: coinListEnabled,
        serviceProviderPaymentsEnabled:
          serviceProviderPaymentsFeatureFlagEnabled,

        stripeAvailableAmountInCents:
          data.statistics.StripeAvailable.amountInCents,
        stripePendingAmountInCents: data.statistics.StripePending.amountInCents,
        stripeAvailableSoonAmountInCents:
          data.statistics.StripeAvailableSoon.amountInCents,
        stripePayoutAmountInCents: data.statistics.Payouts.amountInCents,
        stripePendingReversalsInCents: totalPendingReversals,
        stripeAmountInCents: data.statistics.Stripe.amountInCents,

        bankAccounts: bankAccounts,
        payoutTargetCashListId: cashListConfiguration.payoutTargetCashListId,
        bankOutgoingServiceProviderPaymentsAmountInCents:
          serviceProviderPaymentSum,
        serviceProviderPaymentTargetCashListId:
          cashListConfiguration.serviceProviderPaymentTargetCashListId,

        cashAccounts: cashAccounts,
      });

      const minTime = new Promise((resolve) => setTimeout(resolve, 500));
      const [pdf] = await Promise.all([pdfPromise, minTime]);

      pdf.save(
        makeSafeFilename(
          t('accounting.report.pdf-name', {
            nursingHomeName: selectedNursingHome.name,
            date: format(currentDate, 'yyyy-MM-dd'),
          })
        )
      );
    } catch (error) {
      enqueueErrorSnackbar(error, t('errors.something-went-wrong'), {
        variant: 'error',
      });
    } finally {
      setShowAccountingOverviewPdfLoading(false);
    }
  }, [
    bankAccounts,
    cashAccounts,
    cashListConfiguration.payoutTargetCashListId,
    cashListConfiguration.serviceProviderPaymentTargetCashListId,
    coinListEnabled,
    data,
    enqueueErrorSnackbar,
    handlePrintPdfAccountingOverview,
    pdfLogo,
    selectedNursingHome,
    serviceProviderPaymentSum,
    serviceProviderPaymentsFeatureFlagEnabled,
    t,
    totalPendingReversals,
  ]);

  const [openMoneyFlowModal, setOpenMoneyFlowModal] = useState(false);

  const accountBalanceActions = useMemo(() => {
    return [
      {
        label: t('accounting.dashboard.download-button.overview-pdf'),
        onClick: showAccountingOverviewPdf,
      },
      {
        label: t('accounting.dashboard.download-button.in-outflow-csv'),
        onClick: () => {
          setOpenMoneyFlowModal(true);
        },
      },
    ];
  }, [t, showAccountingOverviewPdf]);

  return (
    <Container maxWidth={'lg'}>
      <NursingHomeAccountingBreadcrumbs
        pageTitle={t('accounting.nav.overview')}
        route={nhAppMainPages.NURSING_HOME_ACCOUNTING_OVERVIEW}
        action={
          <Stack
            direction="row"
            gap={1}
            alignContent="center"
            alignSelf="center"
          >
            {reconciliationReportsEnabled ? (
              <DropdownSplitButton
                options={accountBalanceActions}
                width={'fit-content'}
              />
            ) : (
              <LoadingButton
                disabled={false}
                variant="outlined"
                startIcon={
                  <Iconify style={{ marginTop: -4 }} icon="eva:download-fill" />
                }
                onClick={showAccountingOverviewPdf}
                loading={showAccountingOverviewPdfLoading}
              >
                {t('actions.download-pdf')}
              </LoadingButton>
            )}
            <Button
              variant="contained"
              startIcon={<Iconify icon="eva:sync-outline" />}
              onClick={() => setOpenChangeCashModal(true)}
            >
              {t('cashManagement.change-cash')}
            </Button>
            <ChangeCashModal
              open={openChangeCashModal}
              setOpen={setOpenChangeCashModal}
            />
          </Stack>
        }
      />

      <OverviewPageNewContent
        data={data}
        isLoading={isLoading}
        totalPendingReversals={totalPendingReversals}
        isPendingReversalsLoading={isPendingReversalsLoading}
        isLoadingServiceProviderPayments={isLoadingServiceProviderPayments}
        cashAccounts={cashAccounts}
        bankAccounts={bankAccounts}
        cashLists={cashListConfiguration.cashLists}
        serviceProviderPaymentSum={serviceProviderPaymentSum}
        coinListEnabled={coinListEnabled}
        serviceProviderPaymentsEnabled={
          serviceProviderPaymentsFeatureFlagEnabled
        }
      />

      {reconciliationReportsEnabled && (
        <InOutflowReportDownloadModalNew
          open={openMoneyFlowModal}
          setOpen={setOpenMoneyFlowModal}
        ></InOutflowReportDownloadModalNew>
      )}
    </Container>
  );
};

export interface OverviewPageNewContentProps {
  data: PaymentDashboardStatisticsDto | undefined;
  isLoading: boolean;
  totalPendingReversals: number;
  isPendingReversalsLoading: boolean;
  isLoadingServiceProviderPayments: boolean;
  cashAccounts: CashList[];
  bankAccounts: CashList[];
  serviceProviderPaymentSum: number;
  coinListEnabled: boolean;
  serviceProviderPaymentsEnabled: boolean;
  cashLists: CashList[];
}

// eslint-disable-next-line complexity
export const OverviewPageNewContent: FC<OverviewPageNewContentProps> = ({
  data,
  isLoading,
  totalPendingReversals,
  isPendingReversalsLoading,
  isLoadingServiceProviderPayments,
  bankAccounts,
  cashAccounts,
  serviceProviderPaymentSum,
  coinListEnabled,
  serviceProviderPaymentsEnabled,
  cashLists,
}: OverviewPageNewContentProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { fCurrencyCents } = useFormatting();

  const { rowTextProps, totalTextProps } = useMemo(() => {
    return {
      rowTextProps: {
        variant: 'subtitle2' as const,
        color: theme.palette.grey['600'],
      },
      rowSubTextProps: {
        variant: 'caption' as const,
        color: theme.palette.grey['600'],
      },
      totalTextProps: {
        variant: 'subtitle2' as const,
        fontWeight: 700,
      },
    };
  }, [theme.palette.grey]);

  if (
    !data ||
    isLoading ||
    isPendingReversalsLoading ||
    isLoadingServiceProviderPayments
  ) {
    return <LoadingContainer />;
  }

  return (
    <Stack gap={3} maxWidth={925}>
      <Box sx={{ width: '100%' }}>
        <NewAccountingOverviewTotals
          stripe={data.statistics.Stripe.amount}
          receipts={data.statistics.Receipts.amount}
          totalPendingReversals={totalPendingReversals}
          payoutSum={data.statistics.Payouts.amountInCents}
          serviceProviderPaymentSum={serviceProviderPaymentSum}
          cashLists={cashLists}
          coinListEnabled={coinListEnabled}
          serviceProviderPaymentsEnabled={serviceProviderPaymentsEnabled}
        />
      </Box>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        gap={3}
      >
        <Stack
          gap={3}
          direction={coinListEnabled ? 'column' : 'row'}
          flexWrap="wrap"
        >
          <GroupContainer>
            <Title>{t('accounting.dashboard.resident-balance-title')}</Title>
            <Row>
              <Typography {...rowTextProps}>
                {t('accounting.dashboard.resident-balance')}
              </Typography>
              <Typography {...rowTextProps}>
                {fCurrencyCents(data?.totalResidentBalanceInCents ?? 0)}
              </Typography>
            </Row>
            <RowDivider />
            <Row>
              <Typography {...totalTextProps}>
                {t('accounting.dashboard.total-resident-balance')}
              </Typography>
              <Typography {...totalTextProps}>
                {fCurrencyCents(data?.totalResidentBalanceInCents ?? 0)}
              </Typography>
            </Row>
          </GroupContainer>

          <Stripe
            stripeAvailableAmount={
              data.statistics.StripeAvailable.amountInCents
            }
            stripePayoutAmount={data.statistics.Payouts.amountInCents}
            stripeAvailableSoonAmount={
              data.statistics.StripeAvailableSoon.amountInCents
            }
            stripeAmount={data.statistics.Stripe.amountInCents}
            totalPendingReversals={totalPendingReversals}
          />

          {coinListEnabled &&
            bankAccounts.map((bankAccount) => (
              <Bank
                cashListId={bankAccount.id}
                payoutSum={data.statistics.Payouts.amountInCents}
                serviceProviderPaymentSum={serviceProviderPaymentSum}
                serviceProviderPaymentsEnabled={serviceProviderPaymentsEnabled}
              />
            ))}
          <GroupContainer>
            <Title>{t('accounting.dashboard.receipts-title')}</Title>
            <Row>
              <Typography {...rowTextProps}>
                {t('accounting.dashboard.receipts-amount')}
              </Typography>
              <Typography {...rowTextProps}>
                {fCurrencyCents(data.statistics.Receipts.amountInCents)}
              </Typography>
            </Row>
            <RowDivider />
            <Row>
              <Typography {...totalTextProps} fontWeight={700}>
                {t('accounting.dashboard.receipts-total-balance')}
              </Typography>
              <Typography {...totalTextProps} fontWeight={700}>
                {fCurrencyCents(data.statistics.Receipts.amountInCents)}
              </Typography>
            </Row>
          </GroupContainer>
        </Stack>

        {coinListEnabled && (
          <Stack gap={3}>
            {cashAccounts.map((cashAccount) => (
              <Cash cashListId={cashAccount.id} />
            ))}
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

const isValidDate = (d: any): d is Date => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return d instanceof Date && !isNaN(d);
};

interface InOutflowReportDownloadModalNewProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  earliestDate?: Date;
}

const InOutflowReportDownloadModalNew: FC<
  InOutflowReportDownloadModalNewProps
  // eslint-disable-next-line complexity
> = ({ open, setOpen, earliestDate }) => {
  const { t } = useTranslation();
  const { fCsvNumber } = useFormatting();

  const { selectedNursingHome: nursingHome } = useNursingHomeContext();

  const initialStartDate = useMemo(() => {
    let startDate = startOfMonth(startOfDay(new Date()));
    if (earliestDate && startOfDay(earliestDate) > startDate) {
      startDate = startOfDay(earliestDate);
    }
    return startDate;
  }, [earliestDate]);

  const [dateRange, setDateRange] = React.useState<{
    dateFrom: Date | null;
    dateTo: Date | null;
  }>({ dateFrom: initialStartDate, dateTo: endOfDay(new Date()) });

  const { data, isLoading } = useMoneyFlow({
    nursingHomeIds: nursingHome ? [nursingHome.id] : [],
    dateRange: {
      gte: dateRange.dateFrom ?? undefined,
      lte: dateRange.dateTo ?? undefined,
    },
    options: {
      refetchOnWindowFocus: false,
    },
  });

  const { getBatchReceiptDateString } = useGetBatchReceiptDateString();

  const handleClickExport = useCallback(() => {
    if (dateRange.dateFrom && dateRange.dateTo && data) {
      csvDownload({
        // eslint-disable-next-line complexity
        data: data.map((row) => {
          return {
            nursingHomeName: row.nursingHome.name,
            date: row.date,
            type: row.type,
            amount: fCsvNumber(row.amount / 100),
            currency: row.currency,
            ibanLast4: row.ibanLast4,
            status: row.status,
            serviceProviderName: row.serviceProvider?.name ?? null,
            serviceProviderType: row.serviceProvider?.receiptType.name ?? null,
            receiptBatchTitle: row.receiptBatch
              ? getBatchReceiptDateString(
                  row.receiptBatch.useIndividualDates,
                  row.receiptBatch.earliestEntryDate,
                  row.receiptBatch.latestEntryDate,
                  row.receiptBatch.receiptDate
                )
              : null,
            employeeFirstName: row.employee?.firstName ?? null,
            employeeLastName: row.employee?.lastName ?? null,
          };
        }),
        filename: t('accounting.dashboard.csv-export.filename', {
          startDate: formatInTimeZone(
            dateRange.dateFrom,
            Intl.DateTimeFormat().resolvedOptions().timeZone,
            'yyyy-MM-dd'
          ),
          endDate: formatInTimeZone(
            dateRange.dateTo,
            Intl.DateTimeFormat().resolvedOptions().timeZone,
            'yyyy-MM-dd'
          ),
        }),
        headers: [
          t('accounting.dashboard.csv-export.nursing-home-name'),
          t('accounting.dashboard.csv-export.date'),
          t('accounting.dashboard.csv-export.type'),
          t('accounting.dashboard.csv-export.amount'),
          t('accounting.dashboard.csv-export.currency'),
          t('accounting.dashboard.csv-export.iban-last-4'),
          t('accounting.dashboard.csv-export.status'),
          t('accounting.dashboard.csv-export.service-provider-name'),
          t('accounting.dashboard.csv-export.service-provider-type'),
          t('accounting.dashboard.csv-export.receipt-batch-title'),
          t('accounting.dashboard.csv-export.employee-first-name'),
          t('accounting.dashboard.csv-export.employee-last-name'),
        ],
      });
      setOpen(false);
    }
  }, [dateRange, setOpen, data, fCsvNumber, t, getBatchReceiptDateString]);

  const handleClickLastMonth = useCallback(() => {
    const startDates = [startOfMonth(subMonths(startOfDay(new Date()), 1))];
    if (earliestDate) {
      startDates.push(startOfDay(earliestDate));
    }

    setDateRange({
      dateFrom: max(startDates),
      dateTo: endOfMonth(subMonths(endOfDay(new Date()), 1)),
    });
  }, [earliestDate]);

  const lastMonthButtonActive = useMemo(() => {
    return (
      dateRange.dateFrom &&
      dateRange.dateTo &&
      isSameDay(dateRange.dateFrom, startOfMonth(subMonths(new Date(), 1))) &&
      isSameDay(dateRange.dateTo, endOfMonth(subMonths(new Date(), 1)))
    );
  }, [dateRange]);

  const handleClickLastYear = useCallback(() => {
    const startDates = [startOfYear(subYears(startOfDay(new Date()), 1))];
    if (earliestDate) {
      startDates.push(startOfDay(earliestDate));
    }

    setDateRange({
      dateFrom: max(startDates),
      dateTo: endOfYear(subYears(endOfDay(new Date()), 1)),
    });
  }, [earliestDate]);

  const lastYearButtonActive = useMemo(() => {
    return (
      dateRange.dateFrom &&
      dateRange.dateTo &&
      isSameDay(dateRange.dateFrom, startOfYear(subYears(new Date(), 1))) &&
      isSameDay(dateRange.dateTo, endOfYear(subYears(new Date(), 1)))
    );
  }, [dateRange]);

  const earliestDateOlderThanCurrentMonth = useMemo(() => {
    if (earliestDate) {
      return isBefore(earliestDate, startOfMonth(new Date()));
    }
    return true;
  }, [earliestDate]);

  const earliestDateOlderThanCurrentYear = useMemo(() => {
    if (earliestDate) {
      return isBefore(earliestDate, startOfYear(new Date()));
    }
    return true;
  }, [earliestDate]);

  // eslint-disable-next-line complexity
  const dateError = useMemo(() => {
    if (dateRange.dateFrom && dateRange.dateTo) {
      if (dateRange.dateFrom >= dateRange.dateTo) {
        return t('accounting.dashboard.csv-export.modal.date-error');
      } else if (startOfDay(dateRange.dateTo) > startOfDay(new Date())) {
        return t('accounting.dashboard.csv-export.modal.date-in-future-error');
      }
    }
    if (
      dateRange.dateFrom &&
      earliestDate &&
      dateRange.dateFrom < startOfDay(earliestDate)
    ) {
      return t(
        'accounting.dashboard.csv-export.modal.date-in-before-resident-entry-error'
      );
    }
    return undefined;
  }, [dateRange, earliestDate, t]);

  return (
    <Modal open={open} handleClose={() => setOpen(false)}>
      <ModalInner>
        <Box>
          <Card sx={{ p: 3 }}>
            <Typography variant="h5" fontWeight={700} sx={{ mb: 1 }}>
              {t('accounting.dashboard.csv-export.modal.title')}
            </Typography>
            <Typography sx={{ mb: 1 }}>
              {t('accounting.dashboard.csv-export.modal.please-select-range')}:
            </Typography>
            <Stack direction="row" justifyContent="flex-end" gap={1}>
              <DatePicker
                label={t('accounting.dashboard.csv-export.modal.from')}
                value={dateRange.dateFrom}
                onChange={(newValue) => {
                  if (isValidDate(newValue)) {
                    setDateRange({
                      dateFrom: newValue ? startOfDay(newValue) : newValue,
                      dateTo: dateRange.dateTo,
                    });
                  } else {
                    setDateRange({
                      dateFrom: null,
                      dateTo: dateRange.dateTo,
                    });
                  }
                }}
                minDate={earliestDate}
                maxDate={new Date()}
                renderInput={(params) => (
                  <TextField
                    label={t('accounting.dashboard.csv-export.modal.from')}
                    {...params}
                    helperText={dateError}
                    error={!!dateError}
                  />
                )}
              />
              <DatePicker
                label={t('accounting.dashboard.csv-export.modal.to')}
                value={dateRange.dateTo}
                onChange={(newValue) => {
                  if (isValidDate(newValue)) {
                    setDateRange({
                      dateFrom: dateRange.dateFrom,
                      dateTo: endOfDay(newValue),
                    });
                  } else {
                    setDateRange({
                      dateFrom: dateRange.dateFrom,
                      dateTo: null,
                    });
                  }
                }}
                minDate={earliestDate}
                maxDate={new Date()}
                renderInput={(params) => (
                  <TextField
                    placeholder={t(
                      'accounting.dashboard.csv-export.modal.from'
                    )}
                    {...params}
                    error={!!dateError}
                  />
                )}
              />
            </Stack>

            <Stack
              direction="row"
              justifyContent="flex-start"
              gap={1}
              sx={{
                mt: 1,
                mb: 3,
              }}
            >
              <Button
                disabled={!earliestDateOlderThanCurrentMonth}
                onClick={handleClickLastMonth}
                variant={lastMonthButtonActive ? 'contained' : 'outlined'}
                size="small"
              >
                {t('accounting.dashboard.csv-export.modal.last-month')}
              </Button>
              <Button
                disabled={!earliestDateOlderThanCurrentYear}
                onClick={handleClickLastYear}
                variant={lastYearButtonActive ? 'contained' : 'outlined'}
                size="small"
              >
                {t('accounting.dashboard.csv-export.modal.last-year')}
              </Button>
            </Stack>

            <Stack direction="row" justifyContent="flex-end" gap={1}>
              <Button
                variant="outlined"
                disabled={isLoading}
                onClick={() => setOpen(false)}
              >
                {t('accounting.dashboard.csv-export.modal.cancel')}
              </Button>

              <LoadingButton
                color="primary"
                variant="contained"
                loading={isLoading}
                disabled={
                  Boolean(dateError) ||
                  !dateRange.dateFrom ||
                  !dateRange.dateTo ||
                  isLoading ||
                  !data
                }
                onClick={handleClickExport}
              >
                {t('accounting.dashboard.csv-export.modal.export')}
              </LoadingButton>
            </Stack>
          </Card>
        </Box>
      </ModalInner>
    </Modal>
  );
};
