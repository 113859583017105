import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import { nhAppMainPages } from '@pflegenavi/frontend/routing';
import type { CircleFilterValues } from './useResidentsPage';
import { TabFilterValues } from './useResidentsPage';
import { useFeatureFlagEnabled } from '@pflegenavi/frontend/feature-flags';
import { FeatureFlag } from '@pflegenavi/shared/api';

interface NavigateToResidentsPageParams {
  circleFilter?: CircleFilterValues;
  selectedTab?: TabFilterValues;
  searchTerm?: string;
  tablePage?: number;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useNavigateToResidentsPage = () => {
  const navigate = useNavigate();
  const improvedResidentNavigationFeatureFlagEnabled = useFeatureFlagEnabled(
    FeatureFlag.dev_Improved_Resident_Navigation
  );

  const navigateToResidentPage = useCallback(
    (params: NavigateToResidentsPageParams) => {
      const selectedTab = params.selectedTab ?? TabFilterValues.ALL;
      navigate(
        {
          pathname: nhAppMainPages.RESIDENTS,
          search: improvedResidentNavigationFeatureFlagEnabled
            ? `?tabFilter=${selectedTab}`
            : undefined,
        },
        {
          state: improvedResidentNavigationFeatureFlagEnabled
            ? undefined
            : { selectedTab },
        }
      );
    },
    [improvedResidentNavigationFeatureFlagEnabled, navigate]
  );

  return {
    navigateToResidentPage,
  };
};
