import 'reflect-metadata';
import './sentry';
import {
  ScrollToTop,
  SnackbarProvider,
  ThemeProvider,
} from '@pflegenavi/web-components';
import type { FC, PropsWithChildren } from 'react';
import React, { StrictMode, useLayoutEffect, useRef, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { Router } from 'react-router-dom';
import type { BrowserHistory } from 'history';
import { createBrowserHistory } from 'history';

import { QueryCache, QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import App from './app/App';
import {
  AnalyticsApiProvider,
  CashListConfigurationProvider,
  CashManagementApiProvider,
  EmployeesPhoenixApiProvider,
  ImageApiProvider,
  NursingHomePhoenixApiProvider,
  PaymentApiProvider,
  ProfileTypeContext,
  ReceiptBatchPhoenixApiProvider,
  RecurringItemPhoenixApiProvider,
  ReportingApiProvider,
  ResidentApiProvider,
  ResidentPhoenixApiProvider,
  ServiceProviderApiProvider,
  SettlementPhoenixApiProvider,
  TransactionApiProvider,
  UserProfileApiProvider,
} from '@pflegenavi/frontend/api-nursing-home';
import { TenantContextProvider } from '@pflegenavi/frontend/tenant';
import { initI18N, LocaleProvider } from '@pflegenavi/frontend/localization';
import {
  localeStorage,
  LocalizationProvider,
  ThemeLocalization,
} from '@pflegenavi/frontend/localization-web-app';
import { AuthenticationProvider } from '@pflegenavi/frontend/authentication';
import {
  PflegenaviAuthenticationProvider,
  shouldUseNewLogin,
  useAuthentication,
} from '@pflegenavi/shared-frontend/authentication';
import ErrorBoundary from './app/components/ErrorBoundry';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { login } from './keycloak';
import { ExtensionProvider } from '@pflegenavi/frontend/layout';
import { useCashManagementNavConfig } from '@pflegenavi/frontend/pages/cash-management';
import { useReportingNavConfig } from '@pflegenavi/frontend/pages/reporting';
import { useRecurringItemsNavConfig } from '@pflegenavi/frontend/pages/residents';
import { useServiceProviderNavConfig } from '@pflegenavi/frontend/pages/service-provider';
import * as Sentry from '@sentry/react';
import 'virtual:vite-plugin-sentry/sentry-config';

// this is a virtual font file from vite pluging see: https://github.com/stafyniaksacha/vite-plugin-fonts
import 'virtual:fonts.css';
import { useNursingHomeNavConfig } from '@pflegenavi/frontend/pages/nursing-home';
import { useNursingHomeAccountingNavConfig } from '@pflegenavi/frontend/pages/accountingPayments';
import {
  NursingHomeProvider,
  RedirectUrlProvider,
  useNursingHomeContext,
} from '@pflegenavi/frontend/nursing-home-context';
import {
  FeatureFlagDevProvider,
  useFeatureFlagEnabled,
} from '@pflegenavi/frontend/feature-flags';
import { SnowplowTracking } from '@pflegenavi/frontend/tracking/web';
import { TrackingProvider } from '@pflegenavi/frontend/tracking';
import { nhAppMainPages } from '@pflegenavi/frontend/routing';
import { ClientStoredResourcesProvider } from '@pflegenavi/frontend/client-stored-resources-context';
import { FeatureFlag } from '@pflegenavi/shared/api';
import { PhoenixProvider } from '@pflegenavi/shared-frontend/phoenix';
import { getWebWelcomeToken } from '@pflegenavi/shared/utils';
import { getPhoenixApiBaseUrl } from '@pflegenavi/shared-frontend/platform';

window.global ||= window;

function BrowserRouter({ children }: PropsWithChildren): JSX.Element {
  const ref = useRef<BrowserHistory | undefined>(undefined);

  if (!ref.current) {
    ref.current = createBrowserHistory({
      window,
    });
    // @ts-expect-error // reactHistory is untyped
    window.global.reactHistory = ref.current;
  }

  const history = ref.current;
  const [state, setState] = useState({
    action: history.action,
    location: history.location,
  });
  useLayoutEffect(() => history.listen(setState), [history]);
  return (
    <Router
      location={state.location}
      navigationType={state.action}
      navigator={history}
    >
      {children}
    </Router>
  );
}

const tracker = new SnowplowTracking();
tracker.initialize({
  appId: 'nursing-home-web',
});

// @ts-expect-error // pflegenaviApplication is untyped
window.global.pflegenaviApplication = 'nursing-home-web';
// @ts-expect-error // pflegenaviApplicationVersion is untyped
window.global.pflegenaviApplicationVersion =
  import.meta.env.VITE_PLUGIN_SENTRY_CONFIG.release;
await initI18N(localeStorage);

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error) => {
      if (error instanceof Error) {
        Sentry.captureException(error);
      }
    },
  }),
  defaultOptions: {
    mutations: {
      onError: (error) => {
        if (error instanceof Error) {
          Sentry.captureException(error);
        }
      },
    },
  },
});

const NavExtension: FC<PropsWithChildren> = ({ children }) => {
  const includeCashManagement = !useFeatureFlagEnabled(
    FeatureFlag.AccountingPaymentsNew
  );
  const nursingHomeNavConfig = useNursingHomeNavConfig();
  const recurringItemsNavConfig = useRecurringItemsNavConfig();
  const cashManagementNavConfig = useCashManagementNavConfig();
  const nursingHomeAccountingNavConfig = useNursingHomeAccountingNavConfig();
  const reportingNavConfig = useReportingNavConfig();
  const serviceProviderNavConfig = useServiceProviderNavConfig();
  return (
    <ExtensionProvider
      value={[
        recurringItemsNavConfig,
        serviceProviderNavConfig,
        nursingHomeAccountingNavConfig,
        ...(includeCashManagement ? [cashManagementNavConfig] : []),
        reportingNavConfig,
        nursingHomeNavConfig,
      ]}
    >
      {children}
    </ExtensionProvider>
  );
};

const container = document.getElementById('root');

const root = createRoot(container!);

const AuthWrapper: FC<PropsWithChildren> = ({ children }) => {
  const auth = useAuthentication(true);

  return (
    <AuthenticationProvider
      keycloak={auth}
      login={login}
      redirectUri={`${window.location.origin}/${nhAppMainPages.DASHBOARD}`}
      isNewAuth={Boolean(auth)}
    >
      {children}
    </AuthenticationProvider>
  );
};

function redirectIfNecessary(url: string) {
  const hostname = new URL(url).hostname;

  if (hostname !== window.location.hostname) {
    window.location.replace(url);
    return 'redirected';
  }
  return undefined;
}

root.render(
  <ClientStoredResourcesProvider storage={localStorage}>
    <PflegenaviAuthenticationProvider
      storage={localStorage}
      getWelcomeToken={getWebWelcomeToken}
      shouldUseNewLogin={shouldUseNewLogin()}
      apiUrl={getPhoenixApiBaseUrl(undefined)}
      redirectIfNecessary={redirectIfNecessary}
      onLogout={() => queryClient.clear()}
    >
      <AuthWrapper>
        <StrictMode>
          <BrowserRouter>
            <SnackbarProvider>
              <QueryClientProvider client={queryClient}>
                <TenantContextProvider>
                  <NursingHomeProvider>
                    <RedirectUrlProvider>
                      <ServiceProviderApiProvider>
                        <TransactionApiProvider>
                          <ResidentApiProvider>
                            <ResidentPhoenixApiProvider>
                              <ImageApiProvider>
                                <AnalyticsApiProvider>
                                  <ProfileTypeContext.Provider value="employee">
                                    <EmployeesPhoenixApiProvider>
                                      <UserProfileApiProvider>
                                        <CashManagementApiProvider>
                                          <SettlementPhoenixApiProvider>
                                            <CashListConfigurationProvider
                                              useNursingHomeContext={
                                                useNursingHomeContext
                                              }
                                            >
                                              <ReportingApiProvider>
                                                <ReceiptBatchPhoenixApiProvider>
                                                  <RecurringItemPhoenixApiProvider>
                                                    <FeatureFlagDevProvider>
                                                      <NursingHomePhoenixApiProvider>
                                                        <PhoenixProvider>
                                                          <NavExtension>
                                                            <ThemeProvider>
                                                              <LocaleProvider
                                                                localeStorage={
                                                                  localeStorage
                                                                }
                                                              >
                                                                <LocalizationProvider>
                                                                  <ThemeLocalization>
                                                                    <PaymentApiProvider>
                                                                      <DndProvider
                                                                        backend={
                                                                          HTML5Backend
                                                                        }
                                                                      >
                                                                        <ScrollToTop />
                                                                        <ErrorBoundary>
                                                                          <TrackingProvider
                                                                            tracker={
                                                                              tracker
                                                                            }
                                                                          >
                                                                            <App />
                                                                          </TrackingProvider>
                                                                        </ErrorBoundary>
                                                                      </DndProvider>
                                                                    </PaymentApiProvider>
                                                                  </ThemeLocalization>
                                                                </LocalizationProvider>
                                                              </LocaleProvider>
                                                            </ThemeProvider>
                                                          </NavExtension>
                                                        </PhoenixProvider>
                                                      </NursingHomePhoenixApiProvider>
                                                    </FeatureFlagDevProvider>
                                                  </RecurringItemPhoenixApiProvider>
                                                </ReceiptBatchPhoenixApiProvider>
                                              </ReportingApiProvider>
                                            </CashListConfigurationProvider>
                                          </SettlementPhoenixApiProvider>
                                        </CashManagementApiProvider>
                                      </UserProfileApiProvider>
                                    </EmployeesPhoenixApiProvider>
                                  </ProfileTypeContext.Provider>
                                  <ReactQueryDevtools initialIsOpen={false} />
                                </AnalyticsApiProvider>
                              </ImageApiProvider>
                            </ResidentPhoenixApiProvider>
                          </ResidentApiProvider>
                        </TransactionApiProvider>
                      </ServiceProviderApiProvider>
                    </RedirectUrlProvider>
                  </NursingHomeProvider>
                </TenantContextProvider>
              </QueryClientProvider>
            </SnackbarProvider>
          </BrowserRouter>
        </StrictMode>
      </AuthWrapper>
    </PflegenaviAuthenticationProvider>
  </ClientStoredResourcesProvider>
);
