import type { ResidentListItemDto } from '@pflegenavi/shared/api';
import {
  BalanceStatusColors,
  DirectDebitStatus,
  PaymentInitiatedBy,
  PaymentSchedule,
  ResidentPendingChargePaymentStatus,
  ResidentState,
} from '@pflegenavi/shared/api';
import type {
  useFormatDate,
  useFormatting,
} from '@pflegenavi/frontend/localization';
import { differenceInDays } from 'date-fns';
import type { ResidentTableRowModel } from '../interfaces/ResidentTableRowModel';

interface formatResidentTableRowModelOpts {
  fDate: ReturnType<typeof useFormatDate>;
  fCurrency: ReturnType<typeof useFormatting>['fCurrency'];
  t: (key: string) => string;
}

// eslint-disable-next-line complexity
export const formatResidentTableRowModel = (
  resident: ResidentListItemDto,
  nursingHomeMaxPaymentAmount: number,
  cashPayerEnabled: boolean,
  opts: formatResidentTableRowModelOpts
): ResidentTableRowModel => {
  const familyMember = resident?.familyMembers?.[0]
    ? `${resident?.familyMembers?.[0].firstName} ${resident?.familyMembers?.[0].lastName}`
    : undefined;

  const genderString = opts.t(`gender.${resident.gender}`);

  const daysOverdue = differenceInDays(
    new Date(),
    new Date(
      resident.balance < 0
        ? resident.negative_due_date ?? 0
        : resident.threshold_due_date ?? 0
    )
  );

  const balanceString = opts.fCurrency(resident.balance || balanceFallback);

  const getBalanceColor = (): BalanceStatusColors => {
    if (resident.balance >= 0 && resident.lowBalance) {
      return BalanceStatusColors.LOW_BALANCE;
    }
    if (resident.balance < 0) {
      return BalanceStatusColors.NEGATIVE_BALANCE;
    }
    return BalanceStatusColors.ENOUGH_BALANCE;
  };

  const stateString =
    resident.state === ResidentState.archived
      ? opts.t('statusLabel.Inactive')
      : resident.state === ResidentState.active
      ? opts.t('statusLabel.Active')
      : opts.t('statusLabel.AwaitingSettlement');

  return {
    id: resident.id,
    name: resident.name,
    firstName: resident.firstName,
    lastName: resident.lastName,
    residentAccountingId: resident.residentAccountingId ?? '--',
    genderString,
    gender: resident.gender,
    balanceString,
    balance: resident.balance ?? balanceFallback,
    balanceColor: getBalanceColor(),
    lowBalance: resident.lowBalance,
    threshold_due_date: resident.threshold_due_date,
    daysOverdue,
    negativeBalance: resident.balance < 0,
    negative_due_date: resident.negative_due_date,
    familyMember,
    state: resident.state,
    stateString,
    paymentInfo: resident.paymentInfo,
    cashPayer: cashPayerEnabled ? resident.cashPayer : false,
    familyMemberEmailVerified:
      resident.familyMembers && resident.familyMembers[0]?.emailVerified,
    ...getResidentsTableFilterProperties(resident, nursingHomeMaxPaymentAmount),
  };
};

// eslint-disable-next-line complexity,@typescript-eslint/explicit-module-boundary-types
export const getResidentsTableFilterProperties = (
  resident: ResidentListItemDto,
  nursingHomeMaxPaymentAmount: number
) => {
  let settlement = false;
  let familyMemberMissing = false;
  let sepaMissing = false;
  let amountTooHigh = false;
  let onGoingPayments = false;
  let thresholdPayment = false;
  let monthlyPayment = false;
  let manualPayment = false;
  let disabledPayment = false;

  const topUpAmountInCents =
    resident.paymentInfo?.initiatedBy === PaymentInitiatedBy.System
      ? resident.paymentInfo?.topUpAmountInCents
      : 0;

  const residentBalanceInCents = resident.balance * 100;

  const calculatedChargeAmount = topUpAmountInCents - residentBalanceInCents;

  const chargeAmount =
    resident.paymentInfo?.initiatedBy === PaymentInitiatedBy.System &&
    resident.paymentInfo.residentMaxAmount
      ? Math.min(resident.paymentInfo.residentMaxAmount, calculatedChargeAmount)
      : calculatedChargeAmount;

  if (
    resident.state === ResidentState.awaitingSettlement ||
    resident.state === ResidentState.exited
  ) {
    settlement = true;
  }
  if (resident.state === ResidentState.active) {
    if (!resident.familyMembers || resident.familyMembers.length === 0) {
      familyMemberMissing = true;
    }
    if (
      resident.familyMembers &&
      resident.familyMembers.length > 0 &&
      resident.paymentInfo?.initiatedBy === PaymentInitiatedBy.System &&
      resident.paymentInfo?.directDebit === DirectDebitStatus.Inactive
    ) {
      sepaMissing = true;
    }
    if (chargeAmount > nursingHomeMaxPaymentAmount) {
      amountTooHigh = true;
    }
    if (
      resident.paymentInfo?.status &&
      resident.paymentInfo?.status !== ResidentPendingChargePaymentStatus.Idle
    ) {
      onGoingPayments = true;
    }
    if (resident.paymentInfo?.initiatedBy === PaymentInitiatedBy.FamilyMember) {
      manualPayment = true;
    }
    if (resident.paymentInfo?.initiatedBy === PaymentInitiatedBy.System) {
      if (resident.paymentInfo?.schedule === PaymentSchedule.Threshold) {
        thresholdPayment = true;
      }
      if (resident.paymentInfo?.schedule === PaymentSchedule.Monthly) {
        monthlyPayment = true;
      }
    }
    if (resident.paymentInfo?.initiatedBy === PaymentInitiatedBy.Disabled) {
      disabledPayment = true;
    }
  }

  return {
    settlement,
    familyMemberMissing,
    sepaMissing,
    amountTooHigh,
    onGoingPayments,
    thresholdPayment,
    monthlyPayment,
    manualPayment,
    disabledPayment,
  };
};

const balanceFallback = 0;
