import type { FC } from 'react';
import React, { useCallback, useMemo } from 'react';
import type { ImportFamilyMemberFormValues } from './components/ImportFamilyMembersTable';
import {
  createNewFamilyMember,
  ImportFamilyMembersTable,
} from './components/ImportFamilyMembersTable';
import { Box, Container, HeaderBreadcrumbs } from '@pflegenavi/web-components';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  useImportFamilyMembers,
  useImportFamilyMembersPhoenix,
} from '@pflegenavi/frontend/api-nursing-home';
import { useNursingHomeContext } from '@pflegenavi/frontend/nursing-home-context';
import { useSnackbar } from 'notistack';
import type {
  FamilyMemberImportResponseEntryDto,
  FamilyMemberImportResponseEntryPhoenix,
} from '@pflegenavi/shared/api';
import { FeatureFlag, ImportFamilyMemberError } from '@pflegenavi/shared/api';
import type { FormikHelpers } from 'formik/dist/types';
import { useNavigate } from 'react-router-dom';
import { nhAppMainPages } from '@pflegenavi/frontend/routing';
import {
  clearFMImportStateFromLocalStorage,
  loadFMImportStateFromLocalStorage,
} from './hooks/usePersistFMImportState';
import { DEBOUNCE_SAVE_FM_IMPORT_STATE_DELAY_MS } from '@pflegenavi/shared/constants';
import { useFeatureFlagEnabled } from '@pflegenavi/frontend/feature-flags';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ImportFamilyMembersPageProps {}

export const ImportFamilyMembersPage: FC<ImportFamilyMembersPageProps> = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const newAuthenticationEnabled = useFeatureFlagEnabled(
    FeatureFlag.dev_NewAuthenticationFamilyMember
  );

  const { selectedNursingHome } = useNursingHomeContext();

  const { mutateAsync: importFamilyMembers } = useImportFamilyMembers({
    nursingHomeId: selectedNursingHome?.id ?? '',
  });

  const { mutateAsync: importFamilyMembersPhoenix } =
    useImportFamilyMembersPhoenix();

  const initialValues: ImportFamilyMemberFormValues = useMemo(() => {
    const localStorageState = loadFMImportStateFromLocalStorage();
    if (localStorageState) {
      return localStorageState;
    }
    return {
      familyMembers: Array.from({ length: 1 }, () => createNewFamilyMember()),
    };
  }, []);

  const handleSubmit = useCallback(
    // eslint-disable-next-line complexity
    async (
      values: ImportFamilyMemberFormValues,
      formikHelpers: FormikHelpers<ImportFamilyMemberFormValues>
    ) => {
      try {
        if (newAuthenticationEnabled) {
          const dataToSend = values.familyMembers.map((familyMember) => ({
            resident_id: familyMember.residentId,
            first_name: familyMember.firstName,
            last_name: familyMember.lastName,
            email: familyMember.email,
          }));
          const result = await importFamilyMembersPhoenix({
            data: dataToSend,
            nursing_home_id: selectedNursingHome?.id ?? '',
          });

          const failedToImportFamilyMembers: FamilyMemberImportResponseEntryPhoenix[] =
            [];

          result.data.forEach((familyMember, index) => {
            if (!familyMember.success) {
              failedToImportFamilyMembers.push(familyMember);
            }
          });

          if (failedToImportFamilyMembers.length === 0) {
            enqueueSnackbar(t('family-members-import.table.import-success'), {
              variant: 'success',
            });
            setTimeout(() => {
              clearFMImportStateFromLocalStorage();
            }, DEBOUNCE_SAVE_FM_IMPORT_STATE_DELAY_MS + 100);

            navigate(nhAppMainPages.RESIDENTS);
          }
          // entries that we need to reset formik state with
          const failedEntries = values.familyMembers.filter((familyMember) => {
            return failedToImportFamilyMembers.find((failedFamilyMember) => {
              return failedFamilyMember.resident_id === familyMember.residentId;
            });
          });

          formikHelpers.setFieldValue('familyMembers', failedEntries);

          failedToImportFamilyMembers.forEach((familyMember) => {
            const index = failedEntries.findIndex(
              (entry) => entry.residentId === familyMember.resident_id
            );
            if (
              familyMember.error === ImportFamilyMemberError.RESIDENT_NOT_FOUND
            ) {
              formikHelpers.setFieldError(
                `familyMembers.${index}.residentId`,
                t('family-members-import.table.error.resident-not-found')
              );
            } else if (
              familyMember.error ===
              ImportFamilyMemberError.DUPLICATE_RESIDENT_ID
            ) {
              formikHelpers.setFieldError(
                `familyMembers.${index}.residentId`,
                t('family-members-import.table.error.resident-already-used')
              );
            } else if (
              familyMember.error ===
              ImportFamilyMemberError.CONFLICTING_FAMILY_MEMBER_EMAIL
            ) {
              formikHelpers.setFieldError(
                `familyMembers.${index}.residentId`,
                t('family-members-import.table.error.resident-already-have-fm')
              );
            } else {
              formikHelpers.setFieldError(
                `familyMembers.${index}.residentId`,
                t('family-members-import.table.error.server-failed-to-import')
              );
            }
          });
        } else {
          const dataToSend = values.familyMembers.map((familyMember) => ({
            residentId: familyMember.residentId,
            firstName: familyMember.firstName,
            lastName: familyMember.lastName,
            email: familyMember.email,
          }));
          const result = await importFamilyMembers({
            data: dataToSend,
          });

          const failedToImportFamilyMembers: FamilyMemberImportResponseEntryDto[] =
            [];

          result.data.forEach((familyMember, index) => {
            if (!familyMember.success) {
              failedToImportFamilyMembers.push(familyMember);
            }
          });

          if (failedToImportFamilyMembers.length === 0) {
            enqueueSnackbar(t('family-members-import.table.import-success'), {
              variant: 'success',
            });
            setTimeout(() => {
              clearFMImportStateFromLocalStorage();
            }, DEBOUNCE_SAVE_FM_IMPORT_STATE_DELAY_MS + 100);

            navigate(nhAppMainPages.RESIDENTS);
          }
          // entries that we need to reset formik state with
          const failedEntries = values.familyMembers.filter((familyMember) => {
            return failedToImportFamilyMembers.find((failedFamilyMember) => {
              return failedFamilyMember.residentId === familyMember.residentId;
            });
          });

          formikHelpers.setFieldValue('familyMembers', failedEntries);

          failedToImportFamilyMembers.forEach((familyMember) => {
            const index = failedEntries.findIndex(
              (entry) => entry.residentId === familyMember.residentId
            );
            if (
              familyMember.errorMessage ===
              ImportFamilyMemberError.RESIDENT_NOT_FOUND
            ) {
              formikHelpers.setFieldError(
                `familyMembers.${index}.residentId`,
                t('family-members-import.table.error.resident-not-found')
              );
            } else if (
              familyMember.errorMessage ===
              ImportFamilyMemberError.DUPLICATE_RESIDENT_ID
            ) {
              formikHelpers.setFieldError(
                `familyMembers.${index}.residentId`,
                t('family-members-import.table.error.resident-already-used')
              );
            } else if (
              familyMember.errorMessage ===
              ImportFamilyMemberError.CONFLICTING_FAMILY_MEMBER_EMAIL
            ) {
              formikHelpers.setFieldError(
                `familyMembers.${index}.residentId`,
                t('family-members-import.table.error.resident-already-have-fm')
              );
            } else {
              formikHelpers.setFieldError(
                `familyMembers.${index}.residentId`,
                t('family-members-import.table.error.server-failed-to-import')
              );
            }
          });
        }
      } catch (e) {
        enqueueSnackbar(t('family-members-import.table.import-failed'), {
          variant: 'error',
        });
      }
    },
    [
      enqueueSnackbar,
      importFamilyMembers,
      importFamilyMembersPhoenix,
      navigate,
      newAuthenticationEnabled,
      selectedNursingHome?.id,
      t,
    ]
  );

  const validationSchema = Yup.object({
    familyMembers: Yup.array().of(
      Yup.object().shape({
        residentId: Yup.string().required(
          t('family-members-import.table.required.resident')
        ),
        firstName: Yup.string()
          .min(2, t('field.min-length', { length: 2 }))
          .max(255, t('field.max-length', { length: 255 }))
          .required(t('family-members-import.table.required.first-name')),
        lastName: Yup.string()
          .min(2, t('field.min-length', { length: 2 }))
          .max(255, t('field.max-length', { length: 255 }))
          .required(t('family-members-import.table.required.last-name')),
        email: Yup.string()
          .email(t('family-members-import.table.valid.email'))
          .required(t('family-members-import.table.required.email')),
      })
    ),
  });

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      enableReinitialize={false}
      validateOnChange
      validateOnBlur
      validateOnMount={false}
    >
      <Box>
        <Container maxWidth="lg">
          <HeaderBreadcrumbs
            heading={t('residents.main')}
            links={[
              {
                name: t('residents.main'),
                href: '/residents',
              },
              { name: t('family-members-import.title') },
            ]}
          />
          <ImportFamilyMembersTable />
        </Container>
      </Box>
    </Formik>
  );
};
